export function getTerrainFactor(terrainType: string): number {
  const factors: Factors = {
    flat: 1.0,
    hilly: 1.2,
    "very hilly": 1.5,
    mountainous: 2.0,
  }
  return factors[terrainType] || 1
}

export function getConditionFactor(currentCondition: string): number {
  const factors: Factors = {
    "well-kept": 1.0,
    overgrown: 1.5,
    "extremely overgrown": 2.0,
  }
  return factors[currentCondition] || 1
}

export function getObstructionFactor(obstructionsLevel: string): number {
  const factors: Factors = {
    low: 1.0,
    medium: 1.2,
    high: 1.5,
    extreme: 2.0,
  }
  return factors[obstructionsLevel] || 1
}

export function getFrequencyDiscount(serviceFrequency: string): number {
  const discounts: Factors = {
    "one-time": 1.0,
    weekly: 1.05,
    "bi-weekly": 1.0,
    monthly: 0.95,
  }
  return discounts[serviceFrequency] || 1
}
