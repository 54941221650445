import { IconInfoCircle } from "@tabler/icons-react"
import { Tooltip } from "react-tooltip"

type InputProps = {
  label?: string
  name: string
  type?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  className?: string
  labelClassName?: string
  containerClassName?: string
  labelError?: string
  autofocus?: boolean
  maxLength?: number
  tooltipId?: string
  tooltipContent?: string
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
}

export const Input = ({
  label,
  name,
  type,
  value,
  defaultValue,
  placeholder,
  className,
  labelClassName,
  containerClassName = "",
  labelError,
  autofocus,
  maxLength,
  tooltipId,
  tooltipContent,
  onChange,
}: InputProps) => {
  let inputProps = {}

  if (type === "number") {
    inputProps = {
      min: 0,
    }
  }
  return (
    <div
      className={`flex flex-col items-start justify-center gap-2 w-full ${containerClassName}`}
    >
      {label && (
        <div className="w-full flex items-center justify-between">
          <label
            className={`text-zinc-700 text-left text-sm ${
              labelClassName ? labelClassName : ""
            }`}
            htmlFor={name}
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {tooltipId && (
            <>
              <IconInfoCircle
                className="text-zinc-700 cursor-pointer hover:text-zinc-800 transition w-5 h-5"
                data-tooltip-id={tooltipId}
                data-tooltip-content={tooltipContent}
                data-tooltip-place="top"
              />
              <Tooltip id={tooltipId} />
            </>
          )}
        </div>
      )}
      <input
        type={type || "text"}
        name={name}
        autoFocus={autofocus || false}
        autoComplete="off"
        autoCorrect="off"
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        max={maxLength}
        maxLength={maxLength}
        onChange={onChange}
        className={`p-4 py-2 md:py-4 w-full text-zinc-800 bg-zinc-100 text-lg outline-none border-2 border-zinc-400 focus:border-black transition rounded-xl focus:shadow-[4px_4px_0_0_#000] focus:translate-y-[-4px] focus:translate-x-[-4px] ${
          className ? className : ""
        }`}
        {...inputProps}
      />
      <p className="text-red-500 text-sm w-full text-right">{labelError}</p>
    </div>
  )
}
