export function baseCost(
  propertySqFootage: number,
  estimatedSqFtPerHour: number,
  basePriceHourly: number,
  terrainFactor: number,
  conditionFactor: number
): number {
  const averageSpeedSqFtPerHour = estimatedSqFtPerHour // Example speed: 5000 sqft per hour
  const adjustedSpeed =
    averageSpeedSqFtPerHour / (terrainFactor * conditionFactor)
  const hoursNeeded = propertySqFootage / adjustedSpeed
  return hoursNeeded * basePriceHourly
}

export function specialServicesCost(
  specialServices: {
    [key: string]: number
  },
  requestedServices: string[]
): number {
  return requestedServices.reduce((total, service) => {
    return total + (specialServices[service] || 0)
  }, 0)
}
