"use client"
import { Input } from "@/components/Input"
import { CustomerDetails, Quote, UserWithId } from "@/types"
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import Autocomplete from "react-google-autocomplete"
import toast from "react-hot-toast"
import {
  checkIfAddressIsWithinServiceArea,
  saveQuote,
  updateQuoteCount,
} from "../actions"
import { H1Text } from "@/components/Text"
import { calculateQuote } from "@/lib/quoteFunctions/quoteCalculation"
import CountUp from "react-countup"
import GrassLoader from "@/components/GrassLoader"
import { PrimaryButton } from "@/components/Button"
import {
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconCoin,
} from "@tabler/icons-react"
import { EMAIL_REGEX } from "@/utils/email"
import { phoneNumberAutoFormat } from "@/utils/phoneNumberFormat"
import Image from "next/image"
import { use100vh } from "react-div-100vh"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"

const FormColumn = ({ children }: { children: ReactNode }) => {
  return (
    <div className="w-full flex flex-col justify-start items-center gap-2 md:gap-4 bg-white border-2 border-black rounded-2xl shadow-[4px_4px_0_0_#000] p-4">
      {children}
    </div>
  )
}

const terrainTypes = ["flat", "hilly", "very hilly", "mountainous"]
const conditionTypes = ["well-kept", "overgrown", "extremely overgrown"]
const obstructionTypes = ["low", "medium", "high", "extreme"]
const serviceFrequencies = ["one-time", "weekly", "bi-weekly", "monthly"]

const QuoteForm = ({ company }: { company: UserWithId }) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const [token, setToken] = useState("")
  const [domLoaded, setDomLoaded] = useState(false)
  const [formStep, setFormStep] = useState(0)
  const [formValues, setFormValues] = useState({
    terrainFactor: 0,
    conditionFactor: 0,
    obstructionFactor: 0,
    serviceFrequencyFactor: 0,
  })

  const handleVerifyTurnstile = async () => {
    if (token !== "") {
      const res = await fetch("/api/turnstile", {
        method: "POST",
        body: JSON.stringify({ token }),
      })
      const data = await res.json()
      if (data.success) {
        setCanSubmit(true)
      } else {
        setCanSubmit(false)
        toast.error("Cloudflare verification error")
      }
    }
  }

  useEffect(() => {
    handleVerifyTurnstile()
  }, [token])

  const [serviceAreaDetails, setServiceAreaDetails] = useState<{
    coordinates: string
    distance: number
    isWithinServiceArea: boolean
  } | null>(null)

  const [selectedServices, setSelectedServices] = useState<string[]>([])

  const [isCalculating, setIsCalculating] = useState(false)
  const [quote, setQuote] = useState<number | null>(null)
  const [squareFootage, setSquareFootage] = useState(0)
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>({
    name: "",
    email: "",
    phone: "",
    address: "",
    coordinates: "",
    distance_from_company: 0,
  })
  const [phone, setPhone] = useState("")

  const refTurnstile = useRef<TurnstileInstance>(null)

  const [consultationRequested, setConsultationRequested] = useState(false)
  const height = use100vh()
  const handleNextSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const formObj = Object.fromEntries(formData.entries())

    const emailIsValid = EMAIL_REGEX.test(formObj.email as string)

    if (!emailIsValid) {
      toast.error("Invalid email")
      return
    }

    if (phone.length < 12) {
      toast.error("Invalid phone number")
      return
    }

    setFormStep(1)
  }

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (formStep === 0) {
      handleNextSubmit(e)
      return
    }

    const formData = new FormData(e.target as HTMLFormElement)
    const formObj = Object.fromEntries(formData.entries())

    const squareFootage = Number(formObj.propertySqFootage)

    if (squareFootage < 1) {
      toast.error("Please enter a valid property square footage")
      return
    }

    if (selectedServices.length < 1) {
      toast.error("Please select at least one service")
      return
    }

    const customerInfo = {
      name: formObj.name as string,
      email: formObj.email as string,
      phone: phone,
      address: formObj.address as string,
      coordinates: serviceAreaDetails?.coordinates as string,
      distance_from_company: serviceAreaDetails?.distance || 0,
    }
    setCustomerDetails(customerInfo)
    const customerParams: CustomerParams = {
      requested_services: selectedServices,
      obstructions_level: obstructionTypes[formValues.obstructionFactor],
      terrain_type: terrainTypes[formValues.terrainFactor],
      current_condition: conditionTypes[formValues.conditionFactor],
      service_frequency: serviceFrequencies[formValues.serviceFrequencyFactor],
      property_sq_footage: Number(formObj.propertySqFootage),
      distance_from_company: serviceAreaDetails?.distance || 0,
    }

    const businessParams: BusinessParams = {
      id: company.id as string,
      estimated_sq_ft_per_hour: company.estimated_sq_ft_per_hour,
      hourly_rate: company.hourly_rate,
      special_services: company.special_services.reduce(
        (acc, service) => ({
          ...acc,
          [service.id]: service.amount,
        }),
        {}
      ),
      minimum_charge: company.minimum_charge,
    }

    setSquareFootage(Number(formObj.propertySqFootage))

    setIsCalculating(true)

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("")
      }, 4000)
    })

    const quote = await calculateQuote(
      customerParams,
      businessParams,
      company.username as string
    )
    refTurnstile.current?.reset()
    setQuote(quote)
    setIsCalculating(false)

    try {
      await updateQuoteCount(company.id as string)
    } catch (error) {
      console.error("Error updating quote count:", error)
    }
  }

  const [addressCheckLoading, setAddressCheckLoading] = useState<
    boolean | null
  >(null)

  const checkAddressDistance = async (address: string) => {
    setAddressCheckLoading(true)
    try {
      const serviceAreaCheck = await checkIfAddressIsWithinServiceArea(
        company.company_address,
        address
      )

      if (!serviceAreaCheck) {
        toast.error("Error checking address")
        return
      }

      if (serviceAreaCheck.isWithinServiceArea === undefined) {
        toast.error("Error checking address")
        return
      }
      if (!serviceAreaCheck.isWithinServiceArea) {
        toast.error("Address is not within our service area")
        return
      }

      setServiceAreaDetails(serviceAreaCheck)
    } catch (error) {
      console.error("Error checking address:", error)
      setAddressCheckLoading(null)
    } finally {
      setAddressCheckLoading(false)
    }
  }

  const allFieldsCompleted = () => {
    if (typeof window === "undefined") return false

    const form = document?.querySelector("form")
    if (!form) {
      return false
    }

    const inputs = form.querySelectorAll("input")
    for (const input of inputs) {
      if (input.value === null || input.value === undefined) {
        return false
      }
    }
    if (selectedServices.length < 1) return false
    if (squareFootage < 1 || squareFootage === undefined) return false

    return true
  }

  const [requestLoading, setRequestLoading] = useState(false)
  const handleSaveQuote = async () => {
    setRequestLoading(true)
    const quoteData: Quote = {
      company_id: company.id as string,
      quote: quote as number,
      created_at: new Date(),
      customer_details: customerDetails,
      requested_services: selectedServices,
      obstructions_level: obstructionTypes[formValues.obstructionFactor],
      terrain_type: terrainTypes[formValues.terrainFactor],
      current_condition: conditionTypes[formValues.conditionFactor],
      service_frequency: serviceFrequencies[formValues.serviceFrequencyFactor],
      property_sq_footage: squareFootage,
      distance_from_company: serviceAreaDetails?.distance || 0,
      additional_notes: "",
      status: "pending",
    }

    try {
      const res = await saveQuote(quoteData)
      if (res.success) {
        setConsultationRequested(true)
      }
    } catch (error) {
      console.error("Error saving quote:", error)
      toast.error("Error saving quote")
    } finally {
      setRequestLoading(false)
    }
  }

  const isDisabled = () => {
    if (formStep === 0) {
      return !serviceAreaDetails?.isWithinServiceArea
    }

    if (formStep === 1) {
      if (process.env.NEXT_PUBLIC_NODE_ENV !== "development") {
        if (canSubmit) {
          return (
            !serviceAreaDetails?.isWithinServiceArea || !allFieldsCompleted()
          )
        }
      } else {
        return !serviceAreaDetails?.isWithinServiceArea || !allFieldsCompleted()
      }
    }

    return true
  }

  const handlePhoneNumberFormat = (e: ChangeEvent<HTMLInputElement>) => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    setPhone(targetValue)
  }

  if (isCalculating || quote || consultationRequested) {
    return (
      <div
        style={{ height: height || "100%" }}
        className="w-full h-full relative flex items-center justify-center flex-col"
      >
        {isCalculating && (
          <H1Text className="text-lg">Calculating Quote...</H1Text>
        )}

        {!isCalculating && quote && !consultationRequested && (
          <div className="flex flex-col items-center justify-center mb-20 z-[90] animate-fade-in-up">
            <p className="mb-2 text-center text-zinc-700">
              Your quote is estimated at
            </p>
            <p className="flex items-center justify-center text-pink-600 min-w-[220px] min-h-[120px] text-7xl font-bold mb-4 text-center bg-white p-4 rounded-2xl border-4 border-black shadow-[4px_4px_0_0_#000]">
              {/* ${quote} */}
              <CountUp prefix="$" end={quote} />
            </p>
            <p className="text-center text-sm text-zinc-600 mb-4">per month</p>
            <PrimaryButton className="mb-4" handleClick={handleSaveQuote}>
              {requestLoading
                ? "Sending Quote..."
                : "🤝 Request a Consultation"}
            </PrimaryButton>
            <button
              onClick={() => {
                setQuote(null)
                setIsCalculating(false)
                setFormStep(0)
              }}
              className="flex items-center gap-2 text-md text-zinc-600 hover:text-black transition"
            >
              <IconArrowLeft />
              Go Back
            </button>
            {/* <p className="text-zinc-700 text-xs">
              <em>
                This is just an estimated price. An in-person consultation will
                be required.
              </em>
            </p> */}
          </div>
        )}

        {consultationRequested && (
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-spring-green-600 text-4xl">
              <strong>Done!</strong>
            </p>
            <p className="text-center text-zinc-600">
              Your request has been sent to {company.company_name}.<br />
              They will reach out to you shortly.
            </p>
            <button
              onClick={() => {
                setConsultationRequested(false)
                setQuote(null)
                setIsCalculating(false)
                setFormStep(0)
              }}
              className="flex items-center gap-2 text-md text-zinc-600 hover:text-black transition"
            >
              <IconArrowLeft />
              Go Back
            </button>
          </div>
        )}
        <GrassLoader />
      </div>
    )
  }

  if (quote && !isCalculating) {
    return <div className="w-full max-w-xl h-full pb-20"></div>
  }

  return (
    <div className="w-full max-w-xl min-h-[100svh] pt-10 px-2">
      <div className="w-full flex justify-center">
        <Image
          src={company.image || ""}
          alt="image"
          width={100}
          height={100}
          className="rounded-full mb-2 shadow-md"
        />
      </div>
      <H1Text className="mb-2">{company.company_name}</H1Text>
      <p className="text-zinc-600 text-md mb-4 text-center">
        {formStep === 0
          ? "Fill out the form below to get an instant quote!"
          : "Let's get some information about your property."}
      </p>
      <form className="h-full mb-20 " onSubmit={onFormSubmit}>
        <div className={formStep === 0 ? "pb-4" : "hidden"}>
          {/* name, email, phone, address */}
          <FormColumn>
            <Input
              label="Name"
              name="name"
              type="text"
              placeholder="Your Name"
            />
            <Input
              label="Email"
              name="email"
              type="email"
              placeholder="hello@example.com"
            />

            <Input
              label="Phone"
              name="phone"
              type="tel"
              placeholder="555-555-5555"
              value={phone}
              maxLength={12}
              // @ts-ignore
              onChange={handlePhoneNumberFormat}
            />

            <div className="flex flex-col items-start justify-center gap-2 w-full">
              <label
                htmlFor="address"
                className="text-zinc-700 text-sm w-full text-left"
              >
                Address
              </label>
              <Autocomplete
                name="address"
                className="p-4 py-2 md:py-4 w-full text-black bg-zinc-100 text-lg outline-none border-2 border-zinc-400 focus:border-black transition rounded-xl shadow-none focus:shadow-[4px_4px_0_0_#000] focus:translate-y-[-4px] focus:translate-x-[-4px]"
                apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY}
                onPlaceSelected={(place) =>
                  checkAddressDistance(place.formatted_address)
                }
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "us" },
                }}
              />
              <div className="min-h-[20px] w-full flex justify-center">
                {addressCheckLoading && (
                  <p className="text-zinc-700 text-sm">Checking Address...</p>
                )}
                {serviceAreaDetails?.isWithinServiceArea === false && (
                  <p className="text-red-500 text-sm">
                    Sorry! Your Address is not within our service area.
                  </p>
                )}

                {serviceAreaDetails?.isWithinServiceArea === true && (
                  <p className="text-spring-green-600 text-sm">
                    Good news! Your Address is within our service area.
                  </p>
                )}
              </div>
            </div>
          </FormColumn>
        </div>

        <div
          className={
            formStep === 1
              ? "flex flex-col justify-start items-center gap-2 md:gap-4 pb-4"
              : "hidden"
          }
        >
          <FormColumn>
            <label className="text-zinc-700" htmlFor="propertySqFootage">
              Estimated Property Sq Footage
            </label>
            <Input
              type="number"
              name="propertySqFootage"
              placeholder="5000"
              onChange={(e) => {
                const target = e.target as HTMLInputElement
                setSquareFootage(Number(target.value))
              }}
            />
          </FormColumn>
          <FormColumn>
            <label className="text-zinc-700" htmlFor="terrainFactor">
              Terrain
            </label>

            <input
              id="range-slider"
              type="range"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2 md:mb-0"
              min="0"
              max={terrainTypes.length - 1}
              value={formValues?.terrainFactor}
              step={1}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  terrainFactor: Number(e.target.value),
                })
              }}
            />
            <p className="bg-spring-green-600 p-2 rounded-2xl capitalize">
              {terrainTypes[formValues?.terrainFactor] || terrainTypes[0]}
            </p>
          </FormColumn>
          <FormColumn>
            <label className="text-zinc-700" htmlFor="conditionFactor">
              Current Condition
            </label>
            <input
              id="range-slider"
              type="range"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2 md:mb-0"
              min="0"
              max={conditionTypes.length - 1}
              value={formValues?.conditionFactor}
              step={1}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  conditionFactor: Number(e.target.value),
                })
              }}
            />
            <p className="bg-spring-green-600 p-2 rounded-2xl capitalize">
              {conditionTypes[formValues?.conditionFactor] || conditionTypes[0]}
            </p>
          </FormColumn>
          <FormColumn>
            <label className="text-zinc-700" htmlFor="obstructionFactor">
              Amount of Obstructions
            </label>
            <input
              id="range-slider"
              type="range"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2 md:mb-0"
              min="0"
              max={obstructionTypes.length - 1}
              value={formValues?.obstructionFactor}
              step={1}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  obstructionFactor: Number(e.target.value),
                })
              }}
            />
            <p className="bg-spring-green-600 p-2 rounded-2xl capitalize">
              {obstructionTypes[formValues?.obstructionFactor] ||
                obstructionTypes[0]}
            </p>
          </FormColumn>
          <FormColumn>
            <label className="text-zinc-700" htmlFor="serviceFrequency">
              Service Frequency
            </label>
            <input
              id="range-slider"
              type="range"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 mb-2 md:mb-0"
              min="0"
              max={serviceFrequencies.length - 1}
              value={formValues?.serviceFrequencyFactor}
              step={1}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  serviceFrequencyFactor: Number(e.target.value),
                })
              }}
            />
            <p className="bg-spring-green-600 p-2 rounded-2xl capitalize">
              {serviceFrequencies[formValues?.serviceFrequencyFactor] ||
                serviceFrequencies[0]}
            </p>
          </FormColumn>
          <FormColumn>
            <label className="text-zinc-700" htmlFor="estimatedSqFtPerHour">
              Services
            </label>
            <div className="flex justify-center flex-wrap items-center gap-2">
              {company.special_services.map((service) => {
                return (
                  <div
                    role="button"
                    key={service.id}
                    className={`relative cursor-pointer flex w-full gap-2 md:gap-4 justify-center items-center rounded-2xl p-3 transition ${
                      selectedServices.includes(service.id)
                        ? "bg-spring-green-600 shadow-md"
                        : "bg-zinc-500 shadow-none"
                    }`}
                    onClick={() => {
                      if (selectedServices.includes(service.id)) {
                        setSelectedServices(
                          selectedServices.filter((s) => s !== service.id)
                        )
                        return
                      }
                      setSelectedServices([...selectedServices, service.id])
                    }}
                  >
                    {/* <input
                      name={service.id}
                      type="checkbox"
                      checked={selectedServices.includes(service.id)}
                      className="hidden"
                      onChange={() => {
                        if (selectedServices.includes(service.id)) {
                          setSelectedServices(
                            selectedServices.filter((s) => s !== service.id)
                          )
                          return
                        }
                        setSelectedServices([...selectedServices, service.id])
                      }}
                    /> */}
                    <div
                      className={`w-8 h-8 flex items-center justify-center absolute left-2 p-1 rounded-full ${
                        selectedServices.includes(service.id)
                          ? "border-white"
                          : "border-zinc-400"
                      } border-2 transition`}
                    >
                      <IconCheck
                        className={`${
                          selectedServices.includes(service.id)
                            ? "scale-100"
                            : "scale-0"
                        } origin-center transition transform`}
                      />
                    </div>
                    <label htmlFor={service.id} className="flex-1 text-center">
                      {service.name}
                    </label>
                  </div>
                )
              })}
            </div>
          </FormColumn>
        </div>

        {formStep === 1 &&
          process.env.NEXT_PUBLIC_NODE_ENV !== "development" && (
            <div className="w-full flex justify-center mb-4">
              <Turnstile
                id="turnstile-quote-form"
                ref={refTurnstile}
                siteKey={process.env.NEXT_PUBLIC_TURNSTILE_KEY as string}
                onSuccess={(token: string) => {
                  setToken(token)
                }}
                options={{
                  theme: "light",
                  size: "normal",
                }}
              />
            </div>
          )}

        <button
          type="submit"
          disabled={isDisabled()}
          className="w-full flex gap-2 items-center justify-center bg-spring-green-600 hover:bg-spring-green-700 outline-none text-white border-2 border-black shadow-[4px_4px_0_0_#000] active:translate-y-[4px] active:translate-x-[4px] transition active:shadow-[0_0_0_0_#000] rounded-2xl p-4 disabled:cursor-not-allowed disabled:bg-zinc-700"
        >
          {formStep === 0 ? (
            <>
              Next
              <IconArrowRight />
            </>
          ) : (
            <>
              <IconCoin /> Calculate Quote
            </>
          )}
        </button>
        {formStep === 1 && (
          <button
            className="text-zinc-700 hover:text-zinc-900 mt-6 text-center w-full mb-2"
            onClick={() => setFormStep(0)}
          >
            Go Back
          </button>
        )}
      </form>
    </div>
  )
}

export default QuoteForm
