import UserModel from "@/models/user"
import { baseCost, specialServicesCost } from "./costs"
import {
  getConditionFactor,
  getFrequencyDiscount,
  getObstructionFactor,
  getTerrainFactor,
} from "./factors"
import dbConnect from "@/utils/dbConnect"

export async function calculateQuote(
  customerParams: CustomerParams,
  businessParams: BusinessParams,
  username: string
): Promise<number> {
  const terrainFactor = getTerrainFactor(customerParams.terrain_type)
  const conditionFactor = getConditionFactor(customerParams.current_condition)
  const obstructionFactor = getObstructionFactor(
    customerParams.obstructions_level
  )
  const frequencyDiscount = getFrequencyDiscount(
    customerParams.service_frequency
  )

  const base = baseCost(
    customerParams.property_sq_footage,
    businessParams.estimated_sq_ft_per_hour,
    businessParams.hourly_rate,
    terrainFactor * obstructionFactor,
    conditionFactor
  )

  const specialCost = specialServicesCost(
    businessParams.special_services,
    customerParams.requested_services
  )

  const totalCost = (base + specialCost) * frequencyDiscount

  return Number(Math.max(totalCost, businessParams.minimum_charge).toFixed(0))
}
